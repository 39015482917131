@import url('https://fonts.googleapis.com/css2?family=Lexend+Giga&family=Poppins&display=swap');

#events-wrapper {
    min-height: 100vh;
    animation: slideInBottom ease 1.1s;
}

#event-name {
    font-family: 'Lexend Giga', sans-serif;
    font-weight: bold;
}

#event-description {
    font-family: 'Poppins', sans-serif;
}

#lotties {
    max-width: 300px;
}

#event-container {
    @media only screen and (max-width: 900px) {
        flex-wrap: wrap;
      }
}

#register-button {
  border: 0;
  background-color: rgb(90, 201, 46);
  transition: all .3s ease;
  box-shadow: rgb(31, 136, 59) 0px 10px 0px 0px;
  color: hsl(0, 0%, 100%);
  font-size: 13px;
  width: fit-content;
 }
 
 #register-button:active {
  background-color: rgb(90, 201, 46);
  /*50, 168, 80*/
  box-shadow: rgb(31, 136, 59) 0px 0px 0px 0px;
  transform: translateY(5px);
  transition: 200ms;
 }

   @keyframes slideInLeft {
    0% {
      transform: translateX(-100%);
    }
    100% {
      transform: translateX(0);
    }
  }

  @keyframes slideInRight {
    0% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(0);
    }
  }

  @keyframes slideInTop {
    0% {
      transform: translateY(-160%);
    }
    100% {
      transform: translateY(0);
    }
  }

  @keyframes slideInBottom {
    0% {
      transform: translateY(160%);
    }
    100% {
      transform: translateY(0);
    }
  }

  @keyframes fadeInAnimation {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
     }
}

@keyframes scale-in {
    0% {
      transform: scale(0,0);
    }
    100% {
      transform: scale(1,1);
    }
  }


