#contact-parent{
  min-height: 100vh;
}


#contact-heading {
    /* @media only screen and (min-width: 768px) {
        font-size: 2.5vw;
      } */
      font-family: 'Lato', sans-serif;
}

#contact-description, #contact-form, #form-title {
    font-family: 'Montserrat', sans-serif;
    /* font-size: calc(0.7vh + 0.7vw); */
}

#form-input {
  box-shadow: 5px 5px 4px rgb(5, 92, 10)
}

/* the following 2 style blocks make flex items shrink until wrapping is necessary, 
and then expand to fill the new space */
#container {
  animation: fadeInAnimation ease 1.1s;
  @media only screen and (max-width: 1000px) {
    flex-wrap: wrap;
  }
}

#info {
  max-width: 700px;
}

/* #info, #form {
  @media only screen and (min-width: 768px) {
    min-width: 500px;
  }
  flex-grow: 2;
  flex: 2 0 50%; 
} */

  #send-btn {
    font-family: inherit;
    font-size: 20px;
    background: rgb(44, 146, 44);
    color: white;
    padding-left: 0.9em;
    display: flex;
    align-items: center;
    border: none;
    border-radius: 16px;
    overflow: hidden;
    transition: all 0.2s;
    cursor: pointer;
    box-shadow: 5px 5px 4px rgb(5, 92, 10)
  }
  
  #send-btn span {
    display: block;
    margin-left: 0.3em;
    transition: all 0.3s ease-in-out;
  }
  
  #send-btn svg {
    display: block;
    transform-origin: center center;
    transition: transform 0.3s ease-in-out;
  }
  
  #send-btn:hover .svg-wrapper {
    animation: fly-1 0.6s ease-in-out infinite alternate;
  }
  
  #send-btn:hover svg {
    transform: translateX(1.2em) rotate(45deg) scale(1.1);
  }
  
  #send-btn:hover span {
    transform: translateX(5em);
  }
  
  #send-btn:active {
    transform: scale(0.95);
  }
  
  @keyframes fly-1 {
    from {
      transform: translateY(0.1em);
    }
  
    to {
      transform: translateY(-0.1em);
    }
  }
  
  @keyframes slideInTop {
    0% {
      transform: translateY(-160%);
    }
    100% {
      transform: translateY(0);
    }
  }