@import url('https://fonts.googleapis.com/css2?family=Montserrat&family=Noto+Serif+SC:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Serif+SC:wght@500&family=Oswald&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato&family=Oswald&display=swap');

#heading {
    @media only screen and (min-width: 768px) {
      font-size: 3.2vw;
    }
    font-family: 'Oswald', sans-serif;
}

#description {
    font-size: calc(0.7vh + 0.8vw);
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
}

#core {
    animation: fadeInAnimation ease 3s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
}

#slanted {
  clip-path: polygon(0 0, 100% 0, 100% 85%, 0 100%);
}

#stats {
  font-family: 'Lato', sans-serif;
}

#about-heading {
  @media only screen and (min-width: 768px) {
    font-size: 2.5vw;
  }
  font-family: 'Lato', sans-serif;
}

#about-description {
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    font-size: calc(0.8vh + 0.8vw);
}

#header-container {
  @media (max-width: 900px) {
    flex-wrap: wrap;
  }
}

#info-title {
  font-size: calc(0.9vh + 0.75vw);
}

#des {
  font-size: calc(0.75vh + 0.75vw); 
  @media screen and (max-width: 500px) {
    font-size: calc(0.95vh + 0.95vw); 
  }

  @media (max-width: 900px) {
    padding-right: 80px;
    padding-left: 80px;
  }

  @media (max-width: 600px) {
    padding-right: 40px;
    padding-left: 40px;
  }
}

#explore-button {
  border: 0;
  background-color: rgb(90, 201, 46);
  transition: all .3s ease;
  box-shadow: rgb(31, 136, 59) 0px 10px 0px 0px;
  color: hsl(0, 0%, 100%);
 }
 
 #explore-button:active {
  background-color: rgb(90, 201, 46);
  /*50, 168, 80*/
  box-shadow: rgb(31, 136, 59) 0px 0px 0px 0px;
  transform: translateY(5px);
  transition: 200ms;
 }

   @keyframes slideInLeft {
    0% {
      transform: translateX(-100%);
    }
    100% {
      transform: translateX(0);
    }
  }

  @keyframes slideInRight {
    0% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(0);
    }
  }

  @keyframes slideInTop {
    0% {
      transform: translateY(-160%);
    }
    100% {
      transform: translateY(0);
    }
  }

  @keyframes slideInBottom {
    0% {
      transform: translateY(160%);
    }
    100% {
      transform: translateY(0);
    }
  }

  @keyframes fadeInAnimation {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
     }
}

@keyframes scale-in {
    0% {
      transform: scale(0,0);
    }
    100% {
      transform: scale(1,1);
    }
  }


