#mobile {
    display: none;
}

#mobile i{
    color: #071d07;
}

@media screen and (max-width: 769px) {
    #navbar {
        font-size: small;
        background-color: white;
        border-radius: 25px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        position: fixed;
        top: 50px;
        right: -300px;
        width: 150px;
        height: 20vh;
        /* background-color: aquamarine; */
        padding: 10px 0 0 10px;
        transition: 0.3s ease-in-out;
    }

    #navbar.active {
        right: 0px;
    }

    #mobile {
        display: block;
    }

    #mobile li {
        cursor: pointer;
    }
}

#item {
    cursor: pointer;
    position: relative;
    border: none;
    background: none;
    text-transform: uppercase;
    transition-timing-function: cubic-bezier(0.25, 0.8, 0.25, 1);
    transition-duration: 400ms;
    transition-property: color;
}

#item:focus,
#item:hover {
  color: #24A74A;
}

#item:focus:after,
#item:hover:after {
  width: 100%;
  left: 0%;
}

#item:after {
  content: "";
  pointer-events: none;
  bottom: -2px;
  left: 50%;
  position: absolute;
  width: 0%;
  height: 2px;
  background-color: #24A74A;
  transition-timing-function: cubic-bezier(0.25, 0.8, 0.25, 1);
  transition-duration: 400ms;
  transition-property: width, left;
}